"use client";

import { useEffect, useMemo, useState } from "react";
import useAppContext from "@/src/components/appContext/useAppContext";
import {
  GetTaxonomyTreeInput,
  GetTaxonomyTreeOutput,
  TaxonomyCascaderOption,
} from "@/src/types/categories";
import { useApiServerAction } from "../useServerAction";
import useLocalStorageCache from "../useLocalStorageCache";
import { LocalStorageKey } from "@/src/enums/common";
import { CUSTOM_CATEGORY_REVALIDATE_TIME } from "@/src/constants/cache";
import { ServerActionName } from "@/src/actions/enums";
import {
  buildTreeMapPath,
  configureSelectableTreeNodes,
  searchTree,
  TreeNode,
  TreeNodeSelectionConfig,
} from "@/src/utils/category";
import { useCategoryTree } from "../useCategoryTree";
import isNil from "lodash/isNil";

type CategoryTreePath = Record<string, TreeNode[]>;

export function useCustomCategoryOptions() {
  const { ctx } = useAppContext();
  const [loading, setLoading] = useState(true);
  const [categoryOptions, setCategoryOptions] = useState<
    TaxonomyCascaderOption[]
  >([]);
  const [categoryTreePath, setCategoryTreePath] = useState<CategoryTreePath>(
    {}
  );
  const { customCategory } = ctx;
  const cachedLabels = useMemo(() => {
    const cache: Record<string, string> = {};
    const list = ctx.customCategory?.tree;

    if (list) {
      list.forEach((node) => {
        const populateCache = (treeNode: TreeNode) => {
          cache[treeNode.value] = treeNode.label;
          treeNode.children?.forEach(populateCache);
        };
        populateCache(node);
      });
    }

    return cache;
  }, [ctx.customCategory?.tree]);

  useEffect(() => {
    if (customCategory) {
      const { tree } = customCategory;
      const treePath: CategoryTreePath = {};
      tree?.forEach((node) => buildTreeMapPath(node, [], treePath));

      setCategoryOptions(tree || []);
      setCategoryTreePath(treePath);
      setLoading(false);
    }
  }, [customCategory]);

  const categoryOptionsWithSelectableLeafs = useMemo(
    () =>
      configureSelectableTreeNodes(
        categoryOptions,
        TreeNodeSelectionConfig.LEAF_ONLY
      ),
    [categoryOptions]
  );

  const hasChildrenNodes = (categoryNode: number): boolean => {
    const list = ctx.customCategory?.tree;
    if (list) {
      for (let i = 0; i < list.length; i += 1) {
        const result = searchTree(list[i], categoryNode);
        const hasChild = !isNil(result?.children) && result.children.length > 0;
        if (hasChild) {
          return true;
        }
      }
    }
    return false;
  };

  return {
    loading,
    categoryOptions,
    categoryTreePath,
    cachedLabels,
    hasChildrenNodes,
    categoryOptionsWithSelectableLeafs,
  };
}

// Only for root use.
export function useCustomCategoryProvider() {
  const { setContext, ctx } = useAppContext();
  const { enableCustomCategory, taxonomyId } = useCategoryTree();
  const isAuthenticated = !!ctx.user?.id;

  const getTaxonomyTree = useApiServerAction<
    GetTaxonomyTreeInput,
    GetTaxonomyTreeOutput
  >(ServerActionName.GetTaxonomyTree);

  const { value, isLoading, lastUpdated, fetchAndUpdateCache } =
    useLocalStorageCache({
      key: LocalStorageKey.CustomCategory,
      revalidateTime: CUSTOM_CATEGORY_REVALIDATE_TIME,
      shouldRevalidate: !!taxonomyId && isAuthenticated && enableCustomCategory,
      fetchValue: async () => {
        if (taxonomyId) {
          const response = await getTaxonomyTree({
            taxonomyId,
          });

          if (response.data) {
            return {
              tree: response.data.list,
              taxonomyId: response.data?.taxonomyId,
            };
          }
        }

        return null;
      },
    });

  const { tree, taxonomyId: cachedTaxonomyId } = value ?? {};

  useEffect(() => {
    const hasDifferentTaxonomyId = cachedTaxonomyId !== taxonomyId;
    const needUpdateCache =
      hasDifferentTaxonomyId && !isLoading && isAuthenticated;

    if (needUpdateCache) {
      fetchAndUpdateCache({ force: hasDifferentTaxonomyId });
    } else if (tree) {
      setContext((previousValue) => ({
        ...previousValue,
        customCategory: taxonomyId
          ? {
              tree,
              taxonomyId,
              lastUpdated,
            }
          : undefined,
      }));
    }
  }, [tree]);

  return {
    tree,
    lastUpdated,
  };
}

"use client";

import { useEffect, useMemo, useState } from "react";
import {
  buildTreePathLegacy,
  configureSelectableTreeNodes,
  searchTree,
  TreeNodeSelectionConfig,
} from "@/src/utils/category";
import useAppContext from "@/src/components/appContext/useAppContext";
import {
  CascaderOption,
  GetGoogleCategoryListInput,
  GetGoogleCategoryListOutput,
} from "@/src/types/categories";
import { useApiServerAction } from "../useServerAction";
import useLocalStorageCache from "../useLocalStorageCache";
import { LocalStorageKey } from "@/src/enums/common";
import { DEFAULT_REVALIDATE_TIME } from "@/src/constants/cache";
import isNil from "lodash/isNil";
import { ServerActionName } from "@/src/actions/enums";
import { BackendGetLocaleGoogleCategoryInput } from "@/src/types/api/categories";

type CategoryTreePath = Record<number, number[]>;

export function useGoogleCategoryOptions() {
  const { ctx } = useAppContext();
  const [loading, setLoading] = useState(true);
  const [categoryOptions, setCategoryOptions] = useState<CascaderOption[]>([]);
  const [categoryTreePath, setCategoryTreePath] = useState<CategoryTreePath>(
    {}
  );
  const cachedLabels = useMemo(() => {
    const cache: Record<string, string> = {};
    const list = ctx.googleCategory?.list;

    if (list) {
      list.forEach((node) => {
        const populateCache = (treeNode: CascaderOption) => {
          cache[treeNode.value] = treeNode.label;
          treeNode.children?.forEach(populateCache);
        };
        populateCache(node);
      });
    }
    return cache;
  }, [ctx.googleCategory?.list]);

  useEffect(() => {
    if (ctx.googleCategory) {
      const list = ctx.googleCategory?.list;
      const treePath: CategoryTreePath = {};
      list?.forEach((node) => buildTreePathLegacy(node, [], treePath));

      setCategoryOptions(list || []);
      setCategoryTreePath(treePath);
      setLoading(false);
    }
  }, [ctx.googleCategory]);

  const hasChildrenNodes = (categoryNode: number): boolean => {
    const list = ctx.googleCategory?.list;
    if (list) {
      for (let i = 0; i < list.length; i += 1) {
        const result = searchTree(list[i], categoryNode);
        const hasChild = !isNil(result?.children) && result.children.length > 0;
        if (hasChild) {
          return true;
        }
      }
    }
    return false;
  };

  const singleSelectCategoryOptions = useMemo(
    () =>
      configureSelectableTreeNodes(
        categoryOptions,
        TreeNodeSelectionConfig.LEAF_ONLY
      ),
    [categoryOptions]
  );

  return {
    loading,
    categoryOptions,
    categoryTreePath,
    cachedLabels,
    hasChildrenNodes,
    singleSelectCategoryOptions,
  };
}

// Only for root use.
export function useGoogleCategoryProvider() {
  const { setContext, ctx } = useAppContext();
  const currLang = ctx.lang;
  const isAuthenticated = !!ctx.user?.id;

  const getGoogleCategoriesList = useApiServerAction<
    GetGoogleCategoryListInput & BackendGetLocaleGoogleCategoryInput,
    GetGoogleCategoryListOutput
  >(ServerActionName.GetGoogleCategoriesList);

  const { value, isLoading, lastUpdated, fetchAndUpdateCache } =
    useLocalStorageCache({
      key: LocalStorageKey.GoogleCategory,
      revalidateTime: DEFAULT_REVALIDATE_TIME,
      shouldRevalidate: isAuthenticated,
      fetchValue: async () => {
        const response = await getGoogleCategoriesList({
          parentCategoryId: 0,
          includeSubCategories: true,
          language: currLang,
        });

        const list = response?.data?.list;

        if (list?.length) {
          return {
            list,
            lang: currLang,
          };
        }

        return null;
      },
    });

  const { list, lang: cachedLang } = value ?? {};

  useEffect(() => {
    const needUpdateCache =
      cachedLang !== currLang && !isLoading && isAuthenticated;

    if (needUpdateCache) {
      fetchAndUpdateCache({ force: cachedLang !== currLang });
    } else if (list?.length) {
      setContext((previousValue) => ({
        ...previousValue,
        googleCategory: {
          list,
          lastUpdated,
        },
      }));
    }
  }, [list]);

  return {
    list,
    lastUpdated,
  };
}

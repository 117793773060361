import { CoreCategoryTree } from "@/src/enums/category";
import { useFeatureFlag } from "@/src/hooks/useFeatureFlag";
import useAppContext from "../components/appContext/useAppContext";
import { getCustomCategoryTaxonomyId } from "../utils/category";

export function useCategoryTree() {
  const { ctx } = useAppContext();
  const { isCustomCategoryInAttributeEnabled } = useFeatureFlag();

  const coreCategoryTree = isCustomCategoryInAttributeEnabled
    ? ctx.user?.coreCategoryTree ?? CoreCategoryTree.GOOGLE
    : CoreCategoryTree.GOOGLE;

  const taxonomyId = ctx.user?.supplierId
    ? getCustomCategoryTaxonomyId(ctx.user?.supplierId)
    : null;

  const enableGoogleCategory =
    !isCustomCategoryInAttributeEnabled ||
    [CoreCategoryTree.GOOGLE, CoreCategoryTree.TRANSITION].includes(
      coreCategoryTree
    );

  const enableCustomCategory =
    isCustomCategoryInAttributeEnabled &&
    [CoreCategoryTree.TRANSITION, CoreCategoryTree.CUSTOM].includes(
      coreCategoryTree
    );

  return {
    taxonomyId,
    coreCategoryTree,
    enableGoogleCategory,
    enableCustomCategory,
  };
}

import { useState, useEffect } from "react";
import useLocalStorageState from "./useLocalStorageState";
import { LocalStorageKey } from "@/src/enums/common";
import { usePathname } from "next/navigation";
import { isEmpty } from "@/src/utils/validation";

interface CacheEntry<T> {
  value: T;
  timestamp: number;
}

function useLocalStorageCache<T>({
  key,
  initialValue,
  revalidateTime,
  shouldRevalidate,
  fetchValue,
}: {
  key: LocalStorageKey;
  initialValue?: T;
  revalidateTime?: number;
  shouldRevalidate: boolean;
  fetchValue: () => Promise<T>;
}) {
  const [cacheEntry, setCacheEntry, isInitialized] =
    useLocalStorageState<CacheEntry<T> | null>(key, null);
  const [value, setValue] = useState<T | undefined>(initialValue);
  const [isLoading, setIsLoading] = useState<boolean>(!cacheEntry);
  const [lastUpdated, setLastUpdated] = useState<number>(0);
  const pathname = usePathname();

  const fetchAndUpdateCache = async () => {
    setIsLoading(true);
    try {
      const newValue = await fetchValue();
      const shouldSet = !isEmpty(newValue);
      if (shouldSet) {
        const newCacheEntry: CacheEntry<T> = {
          value: newValue,
          timestamp: Date.now(),
        };
        setCacheEntry(newCacheEntry);
        setValue(newValue);
      }
    } catch (error) {
      console.error("Error fetching new value:", error);
    } finally {
      setIsLoading(false);
    }
  };

  const revalidateAndUpdateCache = ({ force }: { force: boolean }) => {
    if ((!isInitialized || !revalidateTime || !shouldRevalidate) && !force) {
      return;
    }
    if (cacheEntry) {
      const currentTime = Date.now();
      const timeElapsed = (currentTime - cacheEntry.timestamp) / 1000;

      if (!force && timeElapsed < (revalidateTime || 0)) {
        setValue(cacheEntry.value);
        setIsLoading(false);
      } else {
        setValue(cacheEntry.value);
        fetchAndUpdateCache();
      }

      setLastUpdated(cacheEntry.timestamp);
    } else {
      fetchAndUpdateCache();
    }
  };

  useEffect(() => {
    revalidateAndUpdateCache({ force: false });
  }, [isInitialized, pathname]);

  return {
    value,
    isLoading,
    lastUpdated,
    fetchAndUpdateCache: revalidateAndUpdateCache,
  };
}

export default useLocalStorageCache;

export enum ChannelEnum {
  AMAZON = "AMAZON",
  LAZADA = "LAZADA",
  MAGENTO = "MAGENTO",
  SHOPEE = "SHOPEE",
  SHOPIFY = "SHOPIFY",
  WOO = "WOO",
}

export enum TrustanaChannelEnum {
  TRUSTANA = "TRUSTANA",
}

export enum LocaleChannelEnum {
  LOCALE = "LOCALE",
}

export enum CustomExportChannelEnum {
  CUSTOM = "CUSTOM",
}

export enum ChannelCategoryEnum {
  GOOGLE = "GOOGLE_CATEGORY",
  LAZADA = "LAZADA_CATEGORY",
  SHOPEE = "SHOPEE_CATEGORY",
  CUSTOM = "CUSTOM_MAIN_CATEGORY",
}

export enum ChannelFunction {
  PRODUCT_EXPORT = "Product Export",
}

export enum ChannelType {
  MARKETPLACE = "Marketplace",
  E_COMMERCE = "E-Commerce",
}

export enum ChannelSettingsKey {
  PRICE = "priceAttributeKey",
  STOCK = "stockAttributeKey",
  NAME = "productNameAttributeKey",
  DESCRIPTION = "productDescriptionAttributeKey",
  CATEGORY = "categoryAttributeKey",
}

export enum UpdateProductChannelMode {
  ADD = "ADD",
  REPLACE = "REPLACE",
}
